console.log('components');

document.addEventListener('scroll', function() {
  var ceoElement = document.getElementById('form_contact');  // Получаем элемент с ID 'ceo'
  var contactForm = document.querySelector('.form__contact');  // Получаем форму контактов
  var ceoPosition = ceoElement.getBoundingClientRect();  // Получаем позицию элемента 'ceo'

  // Проверяем, достигла ли прокрутка элемента 'ceo'
  if (ceoPosition.top <= window.innerHeight) {
      contactForm.style.opacity = '1';  // Делаем форму видимой
      contactForm.style.transform = 'translateY(0)';  // Перемещаем в исходное положение
  } else {
      contactForm.style.opacity = '0';  // Скрываем форму
      contactForm.style.transform = 'translateY(100%)';  // Смещаем обратно вниз
  }
});


$(document).ready(function () {
  // Обработчик клика по ссылкам в нижнем меню
  $('.header__lower-link').click(function (e) {
    e.preventDefault();

    // Показываем затухание
    $('#fadeScreen').css('opacity', '0.8');
    $('#fadeScreen').css('pointer-events', 'auto');

    // Задержка перед переходом, замените на реальную ссылку
    setTimeout(function () {
      window.location.href = e.target.getAttribute('href');
    }, 400); // 500 миллисекунд (0.5 секунды), можете изменить

    // Скрываем затухание после перехода 
    setTimeout(function () {
      $('#fadeScreen').css('opacity', '0');
      $('#fadeScreen').css('pointer-events', 'none');
    },500); // 1000 миллисекунд (1 секунда), можете изменить
  });
});


function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

  

const swiper = new Swiper('.swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,

  parallax:true,

  a11y: {
    prevSlideMessage: 'Previous slide',
    nextSlideMessage: 'Next slide',
  },

  autoplay: {
    delay: 5000,
  },


  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination', // Селектор для пагинации
    clickable: true, // Сделать пагинацию кликабельной
  },

});


var newSwiper = new Swiper('.swiper-container', {

  autoplay: false,


pagination: {
  el: '.swiper-pagination', // Селектор для пагинации
  clickable: true, // Сделать пагинацию кликабельной
},

mousewheel: true,


  // Responsive breakpoints
  breakpoints: {

    320: {
      slidesPerView: 1,
      spaceBetween: 20
    },
   
    680: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    
    1240: {
      slidesPerView: 5,
      spaceBetween: 40
    }
  }

  

});






/*Faq */

document.querySelectorAll('.faq__item-button').forEach((el) => {
    el.addEventListener('click', () => {
      let faqInfo = el.closest('.faq__item').querySelector('.faq__item-info');
      faqInfo.classList.toggle('ac-active');
    });
  });
  


  
  /*Services */
  
  
  // Получаем все элементы "service__item"
  const serviceItems = document.querySelectorAll('.services__item');
  
  // Получаем все элементы "service__container-block"
  const containerBlocks = document.querySelectorAll('.services__container-block');
  
  // Перебираем все элементы "service__item"
  serviceItems.forEach((item, index) => {
    item.addEventListener('click', () => {
      // Удаляем класс "services__item--active" у всех элементов "service__item"
      serviceItems.forEach((item) => {
        item.classList.remove('services__item--active');
      });
  
      // Добавляем класс "services__item--active" только выбранному элементу "service__item"
      item.classList.add('services__item--active');
  
      // Удаляем класс "services__container-block--active" у всех элементов "service__container-block"
      containerBlocks.forEach((block) => {
        block.classList.remove('services__container-block--active');
      });
  
      // Добавляем класс "services__container-block--active" только выбранному элементу "service__container-block"
      containerBlocks[index].classList.add('services__container-block--active');
  
      gsap.fromTo(
        containerBlocks[index].querySelector('.services__container-info'),
        { x: -250, opacity: 0 },
        { x: 0, opacity: 1, duration: 1.5 }
      );
  
      gsap.fromTo(
        containerBlocks[index].querySelector('.services__container-img'),
        { x: 250, opacity: 0 },
        { x: 0, opacity: 1, duration: 1.5}
      );

 
    });
  });




  const $window = $(window);
  const $body = $('body');
  
  class Slideshow {
    constructor (userOptions = {}) {
      const defaultOptions = {
        $el: $('.slideshow'),
        showArrows: false,
        showPagination: true,
        duration: 10000,
        autoplay: true
      }
      
      let options = Object.assign({}, defaultOptions, userOptions);
      
      this.$el = options.$el;
      this.maxSlide = this.$el.find($('.js-slider-home-slide')).length;
      this.showArrows = this.maxSlide > 1 ? options.showArrows : false;
      this.showPagination = options.showPagination;
      this.currentSlide = 1;
      this.isAnimating = false;
      this.animationDuration = 1200;
      this.autoplaySpeed = options.duration;
      this.interval;
      this.$controls = this.$el.find('.js-slider-home-button');
      this.autoplay = this.maxSlide > 1 ? options.autoplay : false;
  
      this.$el.on('click', '.js-slider-home-next', (event) => this.nextSlide());
      this.$el.on('click', '.js-slider-home-prev', (event) => this.prevSlide());
      this.$el.on('click', '.js-pagination-item', event => {
        if (!this.isAnimating) {
          this.preventClick();
    this.goToSlide(event.target.dataset.slide);
        }
      });
  
      this.init();
    }
    
    init() {
      this.goToSlide(1);
      if (this.autoplay) {
        this.startAutoplay();
      }
      
      if (this.showPagination) {
        let paginationNumber = this.maxSlide;
        let pagination = '<div class="pagination"><div class="container">';
        
        for (let i = 0; i < this.maxSlide; i++) {
          let item = `<span class="pagination__item js-pagination-item ${ i === 0 ? 'is-current' : ''}" data-slide=${i + 1}>${i + 1}</span>`;
          pagination  = pagination + item;
        }
        
        pagination = pagination + '</div></div>';
        
        this.$el.append(pagination);
      }
    }
    
    preventClick() {
      this.isAnimating = true;
      this.$controls.prop('disabled', true);
      clearInterval(this.interval);
  
      setTimeout(() => {
        this.isAnimating = false;
        this.$controls.prop('disabled', false);
        if (this.autoplay) {
          this.startAutoplay();
        }
      }, this.animationDuration);
    }
  
    goToSlide(index) {    
      this.currentSlide = parseInt(index);
      
      if (this.currentSlide > this.maxSlide) {
        this.currentSlide = 1;
      }
      
      if (this.currentSlide === 0) {
        this.currentSlide = this.maxSlide;
      }
      
      const newCurrent = this.$el.find('.js-slider-home-slide[data-slide="'+ this.currentSlide +'"]');
      const newPrev = this.currentSlide === 1 ? this.$el.find('.js-slider-home-slide').last() : newCurrent.prev('.js-slider-home-slide');
      const newNext = this.currentSlide === this.maxSlide ? this.$el.find('.js-slider-home-slide').first() : newCurrent.next('.js-slider-home-slide');
      
      this.$el.find('.js-slider-home-slide').removeClass('is-prev is-next is-current');
      this.$el.find('.js-pagination-item').removeClass('is-current');
      
      if (this.maxSlide > 1) {
        newPrev.addClass('is-prev');
        newNext.addClass('is-next');
      }
      
      newCurrent.addClass('is-current');
      this.$el.find('.js-pagination-item[data-slide="'+this.currentSlide+'"]').addClass('is-current');
    }
    
    nextSlide() {
      this.preventClick();
      this.goToSlide(this.currentSlide + 1);
    }
     
    prevSlide() {
      this.preventClick();
      this.goToSlide(this.currentSlide - 1);
    }
  
    startAutoplay() {
      this.interval = setInterval(() => {
        if (!this.isAnimating) {
          this.nextSlide();
        }
      }, this.autoplaySpeed);
    }
  
    destroy() {
      this.$el.off();
    }
  }
  
  (function() {
    let loaded = false;
    let maxLoad = 3000;  
    
    function load() {
      const options = {
        showPagination: true
      };
  
      let slideShow = new Slideshow(options);
    }
    
    function addLoadClass() {
      $body.addClass('is-loaded');
  
      setTimeout(function() {
        $body.addClass('is-animated');
      }, 600);
    }
    
    $window.on('load', function() {
      if(!loaded) {
        loaded = true;
        load();
      }
    });
    
    setTimeout(function() {
      if(!loaded) {
        loaded = true;
        load();
      }
    }, maxLoad);
  
    addLoadClass();
  })();











  


  

  





